import { render, staticRenderFns } from "./Area.vue?vue&type=template&id=b147878c&scoped=true"
import script from "./Area.vue?vue&type=script&lang=js"
export * from "./Area.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b147878c",
  null
  
)

component.options.__file = "Area.vue"
export default component.exports